<template>
    <transition mode="out-in">
        <router-view />
    </transition>
</template>

<script>
export default {
  name: "App",
 
  created() {
    if (this.$cookies.isKey("ENTIDAD")) {
      this.$apiService.setConfigApi(
        this.$cookies.get("token")
      );
    }
      var appendLeadingZeroes = n => {
        if (n <= 9) {
          return "0" + n;
        }
        return n;
      };
   Date.prototype.yyyymmdd = function() {
      
      let utc = this.toLocaleDateString();
      return (
        utc.split("/")[2] +
        "-" +
        appendLeadingZeroes(utc.split("/")[1]) +
        "-" +
        appendLeadingZeroes(utc.split("/")[0])
      );
    };
     Date.prototype.hhmm = function() {
      return (
        this.getHours() -
        (this.getHours() > 12 ? 12 : 0) +
        ":" +
        appendLeadingZeroes(this.getMinutes()) +
        (this.getHours() > 11 ? " PM" : " AM")
      );
    };
    Date.prototype.addDays = function(days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    function datediff(date1, date2) {
      var y1 = date1.getFullYear(),
        m1 = date1.getMonth(),
        d1 = date1.getDate(),
        y2 = date2.getFullYear(),
        m2 = date2.getMonth(),
        d2 = date2.getDate();
      if (d1 < d2) {
        m1--;
        var date = new Date(y2, m2, 1, 12);
        date.setDate(0);
        d1 += date.getDate();
      }
      if (m1 < m2) {
        y1--;
        m1 += 12;
      }
      return [y1 - y2, m1 - m2, d1 - d2];
    }
    Date.prototype.calcularEdad = function(fecha) {
      var fecha_hoy = new Date();
      var ahora_ano = fecha_hoy.getFullYear();
      var ahora_mes = fecha_hoy.getMonth();
      var ahora_dia = fecha_hoy.getDate();
      var values = fecha.split("-");
      var dia = parseInt(values[2]);
      var mes = parseInt(values[1]);
      var ano = parseInt(values[0]);

      var curd = new Date(ahora_ano, ahora_mes, ahora_dia);
      var cald = new Date(ano, mes - 1, dia);
      var dife = datediff(curd, cald);
      return dife[0] + " años, " + dife[1] + " meses y " + dife[2] + " días";
    };
  }
};

</script>
